import React, { useContext } from "react"
import { CtaButton, SmallLabel } from "../components/styledComponents"
import { dynamicString, getAffiliateLinkWithMetadata } from "../../utils/index"
import GeoContext from "../context/geoContext"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "@emotion/styled"
import { depositIcons } from "../../utils/index";
import Rating from "./rating/index"
import FlagCheckmark from "./flagCheckmark.js";

const ProvidersContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  a {
    color: #333;
  }
  a:hover {
    color: #333;
  }
`

const Provider = styled.div`
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  min-width: 300px;

  @media (min-width: 1200px) {
    min-width: unset;
    width: ${props => props.count && props.count === 3 ? "33%" : "20%"};
  }
`

const ContentContainer = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  /* padding-top: 110px !important;*/
  padding-top: 150px !important;

  .providerName {
    display: block;
    font-weight: bold;
    font-size:1.2em
  }

  .bonusContainer {
    display: flex;
    flex-direction: column;
    min-height: 60px;
    justify-content: center;
  }

  .bonus {
    display: block;
    font-weight: bold;
    font-size: 1.1em;
    color: ${props => props.theme.secondaryColor};
  }
`

const LogoContainer = styled.div`
  position: absolute;
  background: #fff;
  text-align: center;
  padding: 0px;
  padding-bottom:0 !important;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
  height: 180px;
  width: 180px;
  display: flex;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.backgroundcolor ? props.backgroundcolor : "#fff"};
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 6%) 0px 0px 10px;
`

const Logo = styled(LazyLoadImage)`
  width: 100%;
  margin: 0 auto;
  object-fit: contain;
`

export default props => {
  const { scrollPosition, pageContext, count, providers, filterRestricted = true, rating = false, flag = false, reviewLinks = false, depositMethods = false, outboundClickPrefix = "featured_", resourceId } = props
  const { site, themeSettings = {} } = pageContext
  const { disableListings = false, disableBonuses = false } = themeSettings
  const geoContext = useContext(GeoContext);

  if (disableListings) return null

  function sliceProviders({ providers, count, geoContext, filterRestricted }) {
    // Filter out restricted
    let _providers = providers.filter(provider => {
      if (!filterRestricted) return true

      const restrictedCountries = provider?.properties?.["restricted-countries"]?.data?.value
      if (filterRestricted && restrictedCountries?.indexOf(geoContext?.countryCode) > -1) {
        return false
      }
      return true
    })

    return _providers?.slice(0, count ? count : providers.length) || []
  }

  function isRestricted({ restrictedCountries = [], geoContext }) {
    return restrictedCountries?.indexOf(geoContext.countryCode) > -1;
  }

  const outputProviders = sliceProviders({
    providers: providers,
    count: count,
    geoContext,
    filterRestricted
  })

  if (!outputProviders?.length) return null

  return (
    <GeoContext.Consumer>
      {geoContext => (
        <ProvidersContainer className="row pt-5">
          {outputProviders?.map((provider, i) => {
            const backgroundColor = provider?.properties?.["background-color"]?.data?.value
            const restricted = isRestricted({ restrictedCountries: provider?.properties?.["restricted-countries"]?.data?.value, geoContext })
            const nakedAffiliateLink = provider?.properties?.["affiliate-link"]?.data?.value

            // Link T&C string template if tc-url is set
            const tcUrl = provider?.properties?.["tc-url"]?.data?.value
            if (tcUrl && provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a") === -1) {
              provider["tc-text"] = `<a href=${tcUrl} rel="noindex nofollow noopener noreferrer">${provider?.["tc-text"]}</a>`
            } else if (provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a href") > -1) {
              provider["tc-text"] = provider["tc-text"]?.replace(/<a href/g, "<a rel='noindex nofollow noopener noreferrer' href")
            }

            return (
              <Provider key={i} count={count}>
                <ContentContainer className="pb-3 px-3">
                  <a
                    href={
                      restricted ? provider?.path : getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `${outboundClickPrefix}${i + 1}`, clickType: "logo", resourceId})
                    }
                    target={restricted ? "_self" : "_blank"}
                    className={restricted ? "d-block" : "outlink d-block"}
                    rel={restricted ? "" : "noindex nofollow noopener noreferrer"}
                    onClick={() => {
                      if (!restricted) {
                        window._paq.push([
                          "trackEvent",
                          `${outboundClickPrefix}${i + 1}`,
                          provider?.name,
                          "Outclick: Logo",
                        ]);
                      }
                  }}>
                    <LogoContainer backgroundcolor={backgroundColor}>
                      <Logo
                        alt={provider.name}
                        scrollPosition={scrollPosition}
                        src={
                          (provider?.properties?.logotype &&
                            provider?.properties?.logotype?.resolved?.[0]
                              ?.url) ||
                          null
                        }

                      />
                    </LogoContainer>
                  </a>

                  <div className="content text-center">
                    <a href={provider.path}>
                      <span className="providerName">{provider.name}</span>
                    </a>
                    {disableBonuses ? <div className="bonusContainer pt-2 pb-1" />:(
                      <div className="bonusContainer pt-2 pb-1">
                        <span className="bonus">{provider?.["bonus-text"]}</span>
                      </div>
                    )}

                    {rating ? (
                      <div className="mb-2">
                        <Rating value={provider?.properties?.["total-score"]?.data?.value / 2} />
                      </div>
                    ):null}

                    {depositMethods && depositIcons({provider, useIds: true})?.length ? (
                      <div className="mt-3 mb-2">
                        <SmallLabel className="mr-3 d-block mb-2">{provider?.properties?.["deposit-method-taxonomies"]?.fieldName || "Deposit methods"}</SmallLabel>
                        {depositIcons({provider, useIds: true})?.map(item => {
                          return <img src={item.url} alt={item.name} className="mr-3 mb-2" style={{height:"15px", maxWidth: "75px"}} />
                        })}
                      </div>
                    ):null}

                    {flag ? (
                      <div className="mb-2 pl-2 d-flex align-items-center justify-content-center">
                        <FlagCheckmark countryCode={geoContext?.countryCode} />
                      </div>
                    ):null}

                    {!restricted ? (
                      <CtaButton
                        href={getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `${outboundClickPrefix}${i + 1}`, resourceId})}
                        target="_blank"
                        className="outlink d-block"
                        rel="noindex nofollow noopener noreferrer"
                        onClick={() => {
                          window._paq.push([
                            "trackEvent",
                            `${outboundClickPrefix}${i + 1}`,
                            provider?.name,
                            "Outclick: Button",
                          ]);
                        }}
                      >
                        {dynamicString({
                          provider: provider,
                          inputString: site["provider-page-cta"] || site["cta"],
                        }) || "Visit casino"}
                      </CtaButton>
                    ) : null}

                    {reviewLinks && provider.path ? (
                      <a href={provider.path} className="d-inline-block mt-2">See review</a>
                    ):null}

                    {provider["tc-text"] ? <div className="terms text-center mt-2" dangerouslySetInnerHTML={{
                      __html: provider?.["tc-text"],
                    }} /> :
                      null}
                  </div>
                </ContentContainer>
              </Provider>
            )}
          )}
        </ProvidersContainer>
      )}
    </GeoContext.Consumer >
  )
}
