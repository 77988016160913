import React, { useRef, useState } from "react"
import styled from "@emotion/styled"
import { Col, Grid } from "react-flexbox-grid"
import { languageMenu, verticalMenu } from "../../utils/index"
import { use100vh } from "react-div-100vh"

const MobileMenu = styled.div`
  background: #fff;
  display: ${(props) => (props.open ? "block" : "none")};
  height: calc(${(props) => props.dynamicHeight}px - 80px);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 3px;

  #mobileMenuContent {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 210px;
    height: calc(100vh - 80px);

    a {
      display: block;
      padding: 15px 0;
      font-size: 18px;
      font-weight: bold;
      user-select: none;
      color: #000;
    }

    .arrow {
      color: #333;
      cursor: pointer;
      user-select: none;
    }
  }
`

const MobileMenuItemContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lightGray};

  .mobileMenuItemExpandable {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .childContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    a {
      font-size: 16px !important;
    }
  }
`

const Flag = styled.img`
  width: 20px;
  height: 20px;
  object-fit: ${(props) => (props.fit ? props.fit : "cover")};
  margin: 0;
  padding: 0;
  margin-bottom: 0px;
  display: inline-block;
  border-radius: 50px;

  @media only screen and (min-width: 768px) {
    width: 25px;
    height: 25px;
  }
`

const MobileMenuItem = (props) => {
  const { menuItem, index } = props
  const [open, setOpen] = useState(false)
  return (
    <MobileMenuItemContainer key={index}>
      <div className="mobileMenuItemExpandable" key={index}>
        <a
          href={menuItem.href}
          target={menuItem?.target ? menuItem.target : "_self"}
          rel="noreferrer"
        >
          {menuItem.title}
        </a>
        {(menuItem?.children && (
          <div
            className="arrow d-flex"
            onClick={() => setOpen(!open)}
            onKeyDown={() => setOpen(!open)}
            role="button"
            aria-label="Expand menu item"
            tabIndex={0}
          >
            {/*open ?  : "▼"*/}
            <svg
              width="12px"
              height="7px"
              viewBox="0 0 12 7"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className="ml-2 pt-1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-6.000000, -9.000000)" fill="#81858B">
                  <path
                    d="M15.88,9.29 L12,13.17 L8.12,9.29 C7.73,8.9 7.1,8.9 6.71,9.29 C6.32,9.68 6.32,10.31 6.71,10.7 L11.3,15.29 C11.69,15.68 12.32,15.68 12.71,15.29 L17.3,10.7 C17.69,10.31 17.69,9.68 17.3,9.29 C16.91,8.91 16.27,8.9 15.88,9.29 L15.88,9.29 Z"
                    id="chevron-down"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        )) ||
          null}
      </div>
      <div className="childContainer">
        {(open &&
          menuItem?.children?.map((childItem, i) => {
            if (!childItem || !childItem.title) return null

            return (
              <a
                href={childItem.href}
                key={i}
                target={menuItem?.target ? menuItem.target : "_self"}
                className="text-center"
                rel="noreferrer"
              >
                {childItem.title}
              </a>
            )
          })) ||
          null}
      </div>
    </MobileMenuItemContainer>
  )
}

const SectionButton = styled(Col)`
  text-align: center;
  background: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: flex-row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const MenuSection = styled.div``

const BackButtonContainer = styled.div`
  background: ${(props) => props.theme.lightGray};
  height: 50px;
`

const BackButton = styled.div`
  padding: 15px 0;
  text-align: left;
  font-weight: 600;
  cursor: pointer;
`

const BottomMenu = styled.div`
  background: ${(props) => props.theme.lightGray};
  position: absolute;
  bottom: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.show ? "block" : "none")};
  /* padding-bottom: 110px !important; */
  /*
  position: sticky; respectively;
  position: -webkit-sticky;
  transform: translateY(-100%);
  */
`

export default (props) => {
  const [activeSection, setActiveSection] = useState("default")
  const { open, pageContext, countryCode } = props
  const { site, mainMenu, themeSettings = {} } = pageContext
  const { hideMenus } = themeSettings
  const contentRef = useRef(null)
  const height = use100vh()

  return (
    <MobileMenu
      open={open ? 1 : undefined}
      className="d-lg-none"
      bottomMenuVisible={activeSection !== "default"}
      dynamicHeight={height}
    >
      <BackButtonContainer
        className={activeSection === "default" ? "d-none" : "d-block"}
      >
        <Grid>
          <BackButton
            onClick={() => {
              contentRef.current.scrollTop = 0
              setActiveSection("default")
            }}
          >
            <svg
              width="7px"
              height="12px"
              viewBox="0 0 7 12"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className="mr-2"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-101.000000, -575.000000)">
                  <rect
                    fill="#F1F1FA"
                    x="0"
                    y="0"
                    width="1440"
                    height="4747"
                  ></rect>
                  <g
                    transform="translate(97.000000, 573.000000)"
                    fill="#1E2124"
                    opacity="0.5"
                  >
                    <g transform="translate(0.350000, 1.000000)">
                      <path
                        d="M9.695,1.74416667 C9.40916667,1.45833333 8.94833333,1.45833333 8.6625,1.74416667 L3.815,6.59166667 C3.5875,6.81916667 3.5875,7.18666667 3.815,7.41416667 L8.6625,12.2616667 C8.94833333,12.5475 9.40916667,12.5475 9.695,12.2616667 C9.98083333,11.9758333 9.98083333,11.515 9.695,11.2291667 L5.47166667,7 L9.70083333,2.77083333 C9.98083333,2.49083333 9.98083333,2.02416667 9.695,1.74416667 Z"
                        id="arrow-left-big"
                      ></path>
                    </g>
                  </g>
                  <g id="header"></g>
                </g>
              </g>
            </svg>
            Menu
          </BackButton>
        </Grid>
      </BackButtonContainer>
      <Grid id="mobileMenuContent" className="px-0" ref={contentRef}>
        <MenuSection
          className={activeSection === "default" ? "d-block" : "d-none"}
        >
          {mainMenu.map((menuItem, i) => {
            if (!menuItem) return null
            if (!menuItem.title) return false

            if (
              hideMenus?.enabled &&
              hideMenus?.ids?.indexOf(menuItem.id) > -1
            ) {
              return null
            }

            return <MobileMenuItem menuItem={menuItem} index={i} key={i} />
          })}
        </MenuSection>

        <MenuSection
          className={activeSection === "verticalMenu" ? "d-block" : "d-none"}
        >
          {verticalMenu(props).map((menuItem, i) => {
            if (!menuItem) return null
            if (!menuItem.title) return false

            return (
              <MobileMenuItem
                menuItem={{
                  title: menuItem.title,
                  href: menuItem.url,
                  target: "_blank",
                }}
                index={i}
                key={i}
              />
            )
          })}
        </MenuSection>

        <MenuSection
          className={activeSection === "languageMenu" ? "d-block" : "d-none"}
        >
          {languageMenu(props).map((menuItem, i) => {
            if (!menuItem) return null
            if (!menuItem.title) return false

            return (
              <MobileMenuItem
                menuItem={{
                  title: menuItem.title,
                  href: menuItem.url,
                  target: "_blank",
                }}
                index={i}
                key={i}
              />
            )
          })}
        </MenuSection>
      </Grid>
      <BottomMenu show={activeSection === "default"}>
        <Grid className="px-3 py-3">
          <SectionButton
            xs={12}
            className="py-3 mb-2"
            onClick={() => setActiveSection("verticalMenu")}
          >
            {pageContext?.vertical?.name}
          </SectionButton>
          <SectionButton
            xs={12}
            className="py-3"
            onClick={() => setActiveSection("languageMenu")}
          >
            {countryCode ? (
              <Flag
                src={`/flags/${countryCode}.svg`}
                alt={countryCode + " Country Flag"}
                fit="cover"
                className="mr-2"
              />
            ) : null}
            {languageMenu(props)?.find((l) => l.active)?.title}
          </SectionButton>
        </Grid>
      </BottomMenu>
    </MobileMenu>
  )
}
