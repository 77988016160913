import React, {useState} from "react";
import styled from "@emotion/styled";
import { Button } from "../components/styledComponents"

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999999;
`;

const Container = styled.div`
  background: ${props => props.theme.tertiaryColor};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 600px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 5px;
  text-align: center;
  z-index: 999999999;

  h3 {
    color: #000;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }

  .close {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .warning {
    color: red;
  }
`;

const Popup = (props) => {
  const { pageContext, content = "", buttons = [] } = props;
  const { themeSettings = {} } = pageContext;

  return (
    <Backdrop>
      <Container onClick={(e) => e.stopPropagation()} className="px-5 py-3">
        <div
          dangerouslySetInnerHTML={{ __html: content }} />
      
      {buttons?.map((button,i) => {
        return (
          <Button
            className="mx-1 my-1"
            role="button"
            tabIndex={i}
            aria-label={button?.label}
            href={button?.href}
            target="_blank"
            >
              {button?.label}
          </Button> 
        )
      })}
        
        {/*
        {showVerificationFailed ? (
          <p class="warning">{showAgeVerification.verificationFailedContent}</p>
        ):null}
        */}
      </Container>
    </Backdrop>
  );
};

export default Popup;
