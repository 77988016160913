import React from "react"

const Full = props => {

  return (
    <svg
      height={props.height || "16"}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          fill={props.activeColor ? props.activeColor:"#fdbb00"}
          d="M13,4.8046875 C13,4.91927141 12.9322923,5.04427016 12.796875,5.1796875 L9.9609375,7.9453125 L10.6328125,11.8515625 C10.6380209,11.888021 10.640625,11.9401038 10.640625,12.0078125 C10.640625,12.117188 10.6132815,12.209635 10.5585938,12.2851562 C10.503906,12.3606775 10.4244797,12.3984375 10.3203125,12.3984375 C10.2213537,12.3984375 10.117188,12.3671878 10.0078125,12.3046875 L6.5,10.4609375 L2.9921875,12.3046875 C2.87760359,12.3671878 2.77343797,12.3984375 2.6796875,12.3984375 C2.57031195,12.3984375 2.48828152,12.3606775 2.43359375,12.2851562 C2.37890598,12.209635 2.3515625,12.117188 2.3515625,12.0078125 C2.3515625,11.9765623 2.35677078,11.9244795 2.3671875,11.8515625 L3.0390625,7.9453125 L0.1953125,5.1796875 C0.0651035156,5.0390618 0,4.91406305 0,4.8046875 C0,4.6119782 0.145831875,4.49218773 0.4375,4.4453125 L4.359375,3.875 L6.1171875,0.3203125 C6.21614633,0.106769766 6.34374922,0 6.5,0 C6.65625078,0 6.78385367,0.106769766 6.8828125,0.3203125 L8.640625,3.875 L12.5625,4.4453125 C12.8541681,4.49218773 13,4.6119782 13,4.8046875 Z"
        ></path>
      </g>
    </svg>
  )
}

const Half = props => {
  return (
    <svg
      height={props.height || "16"}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="halfGradient">
          <stop offset="50%" stopColor={props.activeColor ? props.activeColor:"#fdbb00"}></stop>
          <stop offset="50%" stopColor="#d7d7d7"></stop>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          fill="url(#halfGradient)"
          d="M13,4.8046875 C13,4.91927141 12.9322923,5.04427016 12.796875,5.1796875 L9.9609375,7.9453125 L10.6328125,11.8515625 C10.6380209,11.888021 10.640625,11.9401038 10.640625,12.0078125 C10.640625,12.117188 10.6132815,12.209635 10.5585938,12.2851562 C10.503906,12.3606775 10.4244797,12.3984375 10.3203125,12.3984375 C10.2213537,12.3984375 10.117188,12.3671878 10.0078125,12.3046875 L6.5,10.4609375 L2.9921875,12.3046875 C2.87760359,12.3671878 2.77343797,12.3984375 2.6796875,12.3984375 C2.57031195,12.3984375 2.48828152,12.3606775 2.43359375,12.2851562 C2.37890598,12.209635 2.3515625,12.117188 2.3515625,12.0078125 C2.3515625,11.9765623 2.35677078,11.9244795 2.3671875,11.8515625 L3.0390625,7.9453125 L0.1953125,5.1796875 C0.0651035156,5.0390618 0,4.91406305 0,4.8046875 C0,4.6119782 0.145831875,4.49218773 0.4375,4.4453125 L4.359375,3.875 L6.1171875,0.3203125 C6.21614633,0.106769766 6.34374922,0 6.5,0 C6.65625078,0 6.78385367,0.106769766 6.8828125,0.3203125 L8.640625,3.875 L12.5625,4.4453125 C12.8541681,4.49218773 13,4.6119782 13,4.8046875 Z"
        ></path>
      </g>
    </svg>
  )
}

const Empty = props => {
  return (
    <svg
      height={props.height || "16"}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          fill="#d7d7d7"
          d="M13,4.8046875 C13,4.91927141 12.9322923,5.04427016 12.796875,5.1796875 L9.9609375,7.9453125 L10.6328125,11.8515625 C10.6380209,11.888021 10.640625,11.9401038 10.640625,12.0078125 C10.640625,12.117188 10.6132815,12.209635 10.5585938,12.2851562 C10.503906,12.3606775 10.4244797,12.3984375 10.3203125,12.3984375 C10.2213537,12.3984375 10.117188,12.3671878 10.0078125,12.3046875 L6.5,10.4609375 L2.9921875,12.3046875 C2.87760359,12.3671878 2.77343797,12.3984375 2.6796875,12.3984375 C2.57031195,12.3984375 2.48828152,12.3606775 2.43359375,12.2851562 C2.37890598,12.209635 2.3515625,12.117188 2.3515625,12.0078125 C2.3515625,11.9765623 2.35677078,11.9244795 2.3671875,11.8515625 L3.0390625,7.9453125 L0.1953125,5.1796875 C0.0651035156,5.0390618 0,4.91406305 0,4.8046875 C0,4.6119782 0.145831875,4.49218773 0.4375,4.4453125 L4.359375,3.875 L6.1171875,0.3203125 C6.21614633,0.106769766 6.34374922,0 6.5,0 C6.65625078,0 6.78385367,0.106769766 6.8828125,0.3203125 L8.640625,3.875 L12.5625,4.4453125 C12.8541681,4.49218773 13,4.6119782 13,4.8046875 Z"
        ></path>
      </g>
    </svg>
  )
}

const Renderer = props => {
  switch (props.type) {
    case "full":
      return Full(props)
    case "half":
      return Half(props)
    case "empty":
      return Empty(props)
    default:
      return Empty(props)
  }
}

export default {
  Full,
  Half,
  Empty,
  Renderer,
}
