import React from "react"
import styled from "@emotion/styled"
import Stars from "./stars"

const RatingContainer = styled.div`
  &&& {
    display: flex !important;
  }

  svg {
    margin: 0 3px;
  }
`

const StarsContainer = styled.div``

export default class Rating extends React.Component {
  constructor(props) {
    super()
  }

  render() {
    const { activeColor } = this.props
    let value = Number(this.props.value).toFixed(1)
    if (!this.props.value) {
      return null
    }
    return (
      <RatingContainer className="p-0 text-center">
        <StarsContainer className="p-0 text-center m-auto text-nowrap">
          {Array(5)
            .fill(0)
            .map((_, i) => {
              value -= 1
              if (value + 1 > 0.7) {
                return <Stars.Renderer height="20" type="full" key={i} activeColor={activeColor}/>
              } else if (value + 1 >= 0.3) {
                return <Stars.Renderer height="20" type="half" key={i} activeColor={activeColor}/>
              } else {
                return <Stars.Renderer height="20" type="empty" key={i} activeColor={activeColor}/>
              }
            })}
        </StarsContainer>
      </RatingContainer>
    )
  }
}
