import React, { useState } from "react"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import cloudinary from "../../utils/cloudinary"
import { languageMenu, verticalMenu } from "../../utils/index"
import { Grid } from "react-flexbox-grid"
import DropdownMenu from "./dropdownMenu"
import MobileMenu from "./mobileMenu"

const StyledGrid = styled(Grid)`
  width: 100% !important;
`

const VerticalBar = styled(Grid)`
  display: flex;
  background: ${(props) => props.theme.verticalBarBackground};
  color: #fff;
  min-height: 32px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  #container  {
    display: flex;
    justify-content: space-between;
  }
  #verticals,
  #languages {
    display: flex;
  }
  .vertical {
    text-transform: none;
    margin-right: 0px;
    color: ${(props) =>
      props.theme.verticalColor
        ? props.theme.verticalColor
        : "rgb(204, 204, 204)"};
    font-weight: 300;
    line-height: 32px;
    white-space: nowrap;
    font-size: 12px !important;
    text-decoration: none;
    padding: 0px 10px;
    cursor: pointer;
  }
  .vertical.active {
    color: rgb(255, 255, 255);
    padding-bottom: 0px;
    font-weight: 600;
    background: rgb(243, 0, 77);
  }
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.open ? "flex-start" : "center")};
  position: ${(props) => (props.open ? "fixed" : "absolute")};
  margin: 0 auto;
  text-align: center;
  width: 100%;
  z-index: 999999;
  background: ${(props) => (props.open ? "#fff" : "transparent")};

  @media only screen and (min-width: 992px) {
    background: transparent;
  }
`

const NavBar = styled(Grid)`
  height: 80px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${(props) =>
    props.open
      ? "1px solid transparent"
      : props.theme.navbarBorderBottom
      ? props.theme.navbarBorderBottom
      : `1px solid ${props.theme.verticalBarBackground}`};
  background: ${(props) => (props.open ? "#fff" : "transparent")};

  @media only screen and (min-width: 992px) {
    background: transparent;
  }
`

const Logo = styled.img`
  float: left;
  height: ${(props) =>
    props.theme.logoHeight ? props.theme.logoHeight : "27px"};
  width: ${(props) =>
    props.theme.logoWidthXs ? props.theme.logoWidthLg : "auto"};
  user-select: none;
  object-fit: contain;

  @media only screen and (max-width: 576px) {
    height: ${(props) =>
      props.theme.logoHeight ? props.theme.logoHeight : "22px"};
    width: ${(props) =>
      props.theme.logoWidthLg ? props.theme.logoWidthXs : "auto"};
  }
`

const Menu = styled.ul`
  padding: 0px;
  margin: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  float: right;
  margin-left: auto;
  height: 70px;
  align-items: center;
`

const MenuItem = styled.li`
  margin-left: 1vw;
  list-style-type: none;
  position: relative;
  padding: 0px;
  height: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline-flex;
  align-items: center;
  height: 100%;

  @media (min-width: 1200px) {
    margin-left: 1.5vw;
  }

  a {
    font-size: 15px;
    text-decoration: none;
    padding: 5px 4px;
    opacity: 1;
    color: #fff;
    font-weight: 600;

    @media (min-width: 768px) {
      font-size: 12px;
    }
    @media (min-width: 1200px) {
      font-size: 14px;
      padding: 5px 4px;
    }
  }

  &:hover {
    .subMenu {
      display: flex !important;
    }
  }

  .subMenu {
    background: #fff;
    padding: 0px;
    position: absolute;
    display: none;
    top: 100%;
    left: 0px;
    transform: translateX(-25%);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
    flex-direction: column;
    text-align: left;
    border-radius: 5px;
    min-width: 140px;
    padding: 5px 0;

    &:before {
      content: " ";
      position: absolute;
      width: 12px;
      height: 12px;
      background: #fff;
      top: -6px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      box-shadow: rgba(84, 84, 84, 0.15) -2px -2px 3px 0px;
      z-index: -1;
    }
    &:after {
      content: " ";
      position: absolute;
      width: 12px;
      height: 12px;
      background: #fff;
      top: -6px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      z-index: 1;
    }

    .submenuItem {
      color: #333;
      padding: 10px 10px;
      z-index: 2;
      white-space: nowrap;
      background: #fff;
      border-radius: 5px;
    }

    .submenuItem:hover {
      background: #f9f9f9;
      border-radius: 5px;
    }
  }
`

const CountryButton = styled.div`
  float: left;
  display: flex;
  color: ${(props) =>
    props.theme.verticalColor ? props.theme.verticalColor : "#fff"};
  font-size: 9px;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  -moz-user-select: none;

  & > span {
    font-weight: 300;
    font-size: 12px;
    margin-left: 5px;
    text-transform: none;
    min-width: 55px;
    display: block;
  }

  @media (min-width: 768px) {
    margin-right: 20px;
  }
`
const CountryFlag = styled.img`
  width: 35px;
  height: 32px;
  object-fit: contain;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    margin-right: ${(props) => (props.rtl ? 0 : "7px")};
    margin-left: ${(props) => (props.rtl ? "7px" : 0)};
  }
`

const MenuToggle = styled.div`
  right: ${(props) => (props.rtl ? "auto" : "15px")};
  left: ${(props) => (props.rtl ? "15px" : "auto")};
  position: absolute;
  height: 30px;
  width: 40px;
  cursor: pointer;
  user-select: none;
  top: 50%;
  transform: translateY(-50%);
`

const VerticalMenuComponent = (props) => {
  const verticals = verticalMenu(props)
  return (
    <div id="verticals" className="px-0">
      {verticals.map((v) => {
        if (v.active) return null

        return (
          <a className="vertical" href={v.url}>
            {v?.title}
          </a>
        )
      })}
    </div>
  )
}

const LanguageMenuComponent = (props) => {
  const menuItems = languageMenu(props)
  const { rtl } = props

  return (
    <div
      id="languages"
      style={{ background: "rgba(255,255,255,0.1)" }}
      className="px-2"
    >
      <DropdownMenu
        menuItems={menuItems}
        alignright={rtl ? 0 : 1}
        columns={1}
        buttonTextColor={props.color || "#000"}
      />
    </div>
  )
}

export default (props) => {
  const [open, setOpen] = useState(false)
  const {
    pageContext,
    invert,
    headerBackground,
    verticalBarDark,
    countryName,
    countryCode,
  } = props
  const { site, mainMenu, themeSettings = {} } = pageContext
  const { hideMenus } = themeSettings

  const theme = useTheme()
  const rtl = site?.properties?.rtl?.data?.value
  const logotype =
    theme?.logotype ||
    cloudinary.url(site?.properties?.logotype?.resolved?.[0]?.url)
  const alternativeLogotype = themeSettings["alternative-logotype"]
    ? themeSettings["alternative-logotype"]
    : cloudinary.url(
        site?.properties?.["alternative-logotype"]?.resolved?.[0]?.url
      )

  return (
    <React.Fragment>
      <NavContainer background={headerBackground} open={open}>
        <VerticalBar dark={verticalBarDark} className="d-none d-lg-block px-0">
          <StyledGrid id="container" className="px-0">
            <VerticalMenuComponent {...props} />

            <div className="float-right d-flex flex-row">
              <CountryButton>
                {countryCode && countryCode !== null ? (
                  <CountryFlag
                    src={`/flags/${countryCode}.svg`}
                    alt={countryCode + " Country Flag"}
                    rtl={rtl}
                  />
                ) : null}
                <span className="d-none d-md-block">{countryName}</span>
              </CountryButton>
              <LanguageMenuComponent
                {...props}
                rtl={rtl}
                color={theme.verticalColor}
              />
            </div>
          </StyledGrid>
        </VerticalBar>

        <NavBar open={open}>
          <a href="/">
            <Logo
              alt={site?.properties?.name?.data?.value}
              src={invert ? alternativeLogotype || logotype : logotype}
            />
          </a>

          <MenuToggle
            onClick={() => setOpen(!open)}
            className="d-inline-block d-lg-none"
            invert={invert ? 1 : undefined}
            open={open ? 1 : undefined}
            rtl={rtl}
          >
            {!open ? (
              <svg
                width="36px"
                height="36px"
                viewBox="0 0 36 36"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g transform="translate(-319.000000, -13.000000)">
                    <rect
                      fill="url(#radialGradient-1)"
                      x="0"
                      y="0"
                      width="375"
                      height="469"
                    ></rect>
                    <g transform="translate(20.000000, 20.000000)"></g>
                    <g transform="translate(319.000000, 13.000000)">
                      <circle
                        fill-opacity="0.1"
                        fill="#FFFFFF"
                        cx="18"
                        cy="18"
                        r="18"
                      ></circle>
                      <path
                        d="M27,23 L27,25 L17,25 L17,23 L27,23 Z M27,17 L27,19 L11,19 L11,17 L27,17 Z M27,11 L27,13 L9,13 L9,11 L27,11 Z"
                        id="Combined-Shape"
                        fill="#D8D8D8"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            ) : (
              <svg
                width="36px"
                height="36px"
                viewBox="0 0 36 36"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g transform="translate(-319.000000, -13.000000)">
                    <g transform="translate(337.000000, 31.000000) scale(-1, 1) translate(-337.000000, -31.000000) translate(319.000000, 13.000000)">
                      <circle
                        fill={themeSettings?.theme?.secondaryColor || "#7857FF"}
                        cx="18"
                        cy="18"
                        r="18"
                      ></circle>
                      <path
                        d="M27,23 L27,25 L17,25 L17,23 L27,23 Z M27,17 L27,19 L11,19 L11,17 L27,17 Z M27,11 L27,13 L9,13 L9,11 L27,11 Z"
                        id="Combined-Shape"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            )}
          </MenuToggle>
          <Menu className="d-none d-lg-flex">
            {mainMenu.map((menuItem, i) => {
              if (!menuItem) return null
              if (!menuItem.title) return false

              if (
                hideMenus?.enabled &&
                hideMenus?.ids?.indexOf(menuItem.id) > -1
              ) {
                return null
              }
              return (
                <MenuItem
                  key={i}
                  invert={invert ? 1 : undefined}
                  hasChildren={menuItem.children ? 1 : undefined}
                >
                  <a href={menuItem.href}>{menuItem.title}</a>

                  {menuItem.children ? (
                    <svg
                      width="12px"
                      height="7px"
                      viewBox="0 0 12 7"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className="ml-0 pt-1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-6.000000, -9.000000)"
                          fill="#fff"
                        >
                          <path
                            d="M15.88,9.29 L12,13.17 L8.12,9.29 C7.73,8.9 7.1,8.9 6.71,9.29 C6.32,9.68 6.32,10.31 6.71,10.7 L11.3,15.29 C11.69,15.68 12.32,15.68 12.71,15.29 L17.3,10.7 C17.69,10.31 17.69,9.68 17.3,9.29 C16.91,8.91 16.27,8.9 15.88,9.29 L15.88,9.29 Z"
                            id="chevron-down"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  ) : null}

                  {(menuItem.children && (
                    <div className="subMenu">
                      {menuItem?.children?.map((child, i) => {
                        if (!child || !child.href) return null
                        return (
                          <a href={child.href} className="submenuItem" key={i}>
                            {child.title}
                          </a>
                        )
                      })}
                    </div>
                  )) ||
                    null}
                </MenuItem>
              )
            })}
          </Menu>
        </NavBar>

        <MobileMenu {...props} open={open} />
      </NavContainer>
    </React.Fragment>
  )
}
