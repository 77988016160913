function getAffiliateLinkWithMetadata({affiliateLink, position, clickType = "button", resourceId}) {
  if (typeof window == 'undefined') {
    return affiliateLink
  }

  let data = {}
  const dataLayer = JSON.parse(JSON.stringify(window?.dataLayer))

  if (dataLayer) {
    const dataLayerData = dataLayer.find(item => {
      return item.event === "pageview"
    })
    if (dataLayerData) {
      data = dataLayerData
      delete data.event
    }
  }

  data.url = window.location.href
  data.position = position
  data.clickType = clickType
  data.resourceId = resourceId

  if (data?.country) {
    data.siteGeo = data.country
    delete data.country
  }

  const stringifiedData = encodeURIComponent(JSON.stringify(data))
  let encodedData

  try {
    encodedData = window.btoa(stringifiedData)
  } catch {
    return affiliateLink
  }

  return `${affiliateLink}?d=${encodedData}`
}

function dynamicString({ inputString = "", provider = {}, product = {}, site = {}, vertical = {} }) {
  if (inputString === null) {
    return ""
  }
  const abbrNiche = vertical?.["abbreviated-name"] || vertical?.name

  inputString = inputString.replace(/％/g, "%")
  inputString = inputString.replace(/%PROVIDERNAME/g, provider.name)
  inputString = inputString.replace(/%PROVIDER/g, provider.name)
  inputString = inputString.replace(/%NAME/g, provider.name)
  inputString = inputString.replace(/%PRODUCTNAME/g, product.name)
  inputString = inputString.replace(/%YEAR/g, new Date().getFullYear())
  inputString = inputString.replace(/%SITE_URL/g, site.url);
  inputString = inputString.replace(/%VERTICAL/g, vertical?.name);
  inputString = inputString.replace(/%ABBRNICHE/g, abbrNiche);
  inputString = inputString.replace(/%SHORTNICHE/g, abbrNiche);
  inputString = inputString.replace(/%NICHE/g, vertical?.name);
  inputString = inputString.replace(
    /%SITENAME/g,
    site?.properties?.name?.data?.value)
  inputString = inputString.replace(/%BONUSAMOUNT/g, provider?.["bonus-text"]);
  inputString = inputString.replace(/%YEAR/g, new Date().getFullYear());
  inputString = inputString.replace(
    /%MONTH/g,
    new Date().toLocaleString("en-us", { month: "long" })
  );

  return inputString
}


function basicSlug(value) {
  if (!value) return

  let slug = value
    .toLowerCase()
    .trim()
    .replace(
      /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,./:;<=>?@[\]^`{|}~]/g,
      ""
    )
    .replace(/\s/g, "-")

  return slug
}

function rankProviders({ providers = [], geoContext = {} }) {
  const { rankingData = [], countryCode } = geoContext

  providers = providers.map(p => {
    if (!p) return null
    p.weight = 0

    if (rankingData && rankingData.length) {
      const rankingNode = rankingData.find(r => {
        return String(r.id) === String(p.allanId)
      })
      if (rankingNode) {
        p.weight = rankingData.length - rankingData.indexOf(rankingNode)
      }
    }
    return p
  })

  // Filter out restricted providers
  if (countryCode) {
    providers = providers.filter(p => {
      const restrictedCountries = p?.properties?.["restricted-countries"]?.data?.value

      if (!restrictedCountries) {
        return true
      }

      return restrictedCountries?.indexOf(countryCode) === -1
    })
  }

  providers.sort(function (a, b) {
    return a.weight > b.weight ? -1 : a.weight < b.weight ? 1 : 0
  })

  return providers
}


function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

function getContentFieldKeysFromTemplate({ type, templates }) {
  return templates?.find(
    (template) => template.type === type
  )?.fields?.map((contentField) => {
    return contentField.key;
  });
}

const cloneDeep = (input) => {
  return JSON.parse(JSON.stringify(input));
};

function checkPathForChars(src, restricted) {
  return src.split("").some((ch) => restricted.indexOf(ch) !== -1);
}

const scoreColors = [{ threshold: 0, color: "red" }, { threshold: 30, color: "#ffa229" }, { threshold: 56, color: "#339900" }, { threshold: 80, color: "#1cc936" }]

function depositIcons(props) {
   const {provider, useIds = false} = props

   if (!useIds) {
     const icons = {
       "Visa": "/depositIcons/visa.svg",
       "MasterCard": "/depositIcons/mastercard.svg",
       "PayPal": "/depositIcons/paypal.svg",
       "Neteller": "/depositIcons/neteller.svg",
       "Skrill": "/depositIcons/skrill.svg",
       "Paysafe Card": "/depositIcons/paysafe.svg",
       "Blik": "/depositIcons/blik.svg",
       "Trustly": "/depositIcons/trustly.svg",
     }

     return provider?.properties?.["deposit-method-taxonomies"]?.resolved?.reduce((output, item) => {
       if (icons?.[item?.name]) {
         output.push({name:item?.name, url: icons?.[item?.name]})
       }
       return output
     }, [])
  } else {
    const icons = {
      "cklmagab0312520omrzl3sjyfe": {name: "Blik", icon: "/depositIcons/blik.svg"},
      "recIHSQAgDd8Hi4oZ": {name: "Visa", icon: "/depositIcons/visa.svg"},
      "recDpaxJA24YcvXye": {name: "MasterCard", icon: "/depositIcons/mastercard.svg"},
      "recC6NeT08YasdODn": {name: "PayPal", icon: "/depositIcons/paypal.svg"},
      "recjwZP0tCdaRZxCf": {name: "Neteller", icon: "/depositIcons/neteller.svg"},
      "recDWPhsfhvAcZl7j": {name: "Skrill", icon: "/depositIcons/skrill.svg"},
      "recq5PBszlsayaJ4P": {name: "Paysafe Card", icon: "/depositIcons/paysafe.svg"},
      "rechK5lqHgElMH3tm": {name: "Trustly", icon: "/depositIcons/trustly.svg"},
    }
    return provider?.properties?.["deposit-method-taxonomies"]?.data?.value?.reduce((output, item) => {
      if (icons?.[item]) {
        output.push({name:icons?.[item]?.name, url: icons?.[item]?.icon})
      }
      return output
    }, [])
  }
}


function trustedIcons(props) {
   const {provider} = props

   const icons = {
     "rec4v8mqpqyTDpZk6": "/licenseIcons/mga.svg",
     "recJRtRuSM4NrK54W": "/licenseIcons/ukgamcom.svg",
     "recSZvBEL6DsKAiXi": "/licenseIcons/curacao.png",
     "ckkmp5j56338650nmpghfv20l4": "/licenseIcons/pagcor.png"
   }

   return provider?.properties?.["license-taxonomies"]?.resolved?.reduce((output, item) => {
     if (icons?.[item?.allanId]) {
       output.push({name:item?.name, url: icons?.[item?.allanId]})
     }
     return output
   }, [])
}


const verticalMenu = props => {
  const { pageContext } = props
  const { site = {}, vertical = {}, verticals: _verticals } = pageContext

  let verticals = []

  const verticalName = vertical?.name
  if (verticalName) {
    verticals.push({
      title: verticalName,
      url: site?.url.indexOf("https://") === 0 ? site?.url : "https://" + site?.url,
      active: true,
    })
  }

  Object.keys(_verticals).forEach(verticalKey => {
    const _vertical = _verticals?.[verticalKey]

    if (vertical?.allanId === _vertical?.allanId) {
      // Skip current vertical
    } else {
      const verticalSite = _vertical?.sites?.find(s => {
        return s.languageId === site?.language?.id
      })
      const verticalName = _vertical?.content?.find(c => c.key === "name")?.strings?.[0]?.data || _vertical?.name

      if (verticalSite && verticalName) {
        verticals.push({
          title: verticalName,
          url: verticalSite?.url.indexOf("https://") === 0 ? verticalSite?.url : "https://" + verticalSite?.url
        })
      }
    }
  })

  // Add Slotsrank if prop exists
  if (site?.properties?.["slotsrank-url"]?.data?.value) {
    verticals.push({
      title: "Slots",
      url: site?.properties?.["slotsrank-url"]?.data?.value,
    })
  }

  if (site?.properties?.["ocr-url"]?.data?.value) {
    verticals.push({
      title: "Online Casino",
      url: site?.properties?.["ocr-url"]?.data?.value,
    })
  }

  if (site?.properties?.["mcr-url"]?.data?.value) {
    verticals.push({
      title: "Mobile Casino",
      url: site?.properties?.["mcr-url"]?.data?.value,
    })
  }

  if (site?.properties?.["lcr-url"]?.data?.value) {
    verticals.push({
      title: "Live Casino",
      url: site?.properties?.["lcr-url"]?.data?.value,
    })
  }

  return verticals
}

const languageMenu = props => {
  const { pageContext, deepLinks = true } = props
  const {
    pageType,
    site = {},
    vertical = {},
    taxonomy = {},
    filterTaxonomy = {},
    provider = {},
    product = {},
    page = {},
    post = {},
    isExtendedReviewSubPage = false,
    extendedReviewSection = {},
    languages
  } = pageContext
  let menuItems = []

  let item
  switch (pageType) {
    case "taxonomyChild":
    case "taxonomyParent":
    case "taxonomyNews":
    case "taxonomyGuides":
      item = taxonomy
      break
    case "taxonomyFilter":
      item = filterTaxonomy
      break
    case "provider":
      item = provider
      break
    case "product":
      item = product
      break
    case "page":
      item = page
      break
    case "post":
      item = post
      break
    default:
      break
  }

  if (vertical?.sites) {
    vertical.sites.forEach(s => {
      let url = s.url
      const language = languages.find(l => l.languageTag === s?.language?.languageTag)

      if (url.indexOf("https://") !== 0 && url.indexOf("http://") !== 0) {
        url = "https://" + url
      }

      let localisedPath = item?.["translatedPaths"]?.[s?.language?.languageTag]

      if (isExtendedReviewSubPage && localisedPath) {
        localisedPath = extendedReviewSection.path
      }

      if (localisedPath && deepLinks) {
        url = url + localisedPath
      }

      menuItems.push({
        title: language.name,
        url: url,
        active: s.url === site.url,
      })
    })
  }

  menuItems = menuItems.sort((a, b) => (a.title > b.title) ? 1 : -1)
  if (!menuItems.length) {
    return null
  }

  return menuItems
}

module.exports = {
  getAffiliateLinkWithMetadata,
  basicSlug,
  dynamicString,
  rankProviders,
  formatDate,
  getContentFieldKeysFromTemplate,
  cloneDeep,
  checkPathForChars,
  scoreColors,
  depositIcons,
  trustedIcons,
  verticalMenu,
  languageMenu
}
