import React, { useEffect, useState } from "react"
import exitIntent from 'exit-intent'
import styled from "@emotion/styled"
import { Global, css, ThemeProvider } from "@emotion/react"
import { GeoProvider } from "../context/geoContext"
import GeoContext from "../context/geoContext"
import "../styles/global.css"
import cloudinary from "../../utils/cloudinary"
import Header from "../components/header"
import Footer from "../components/footer"
import ExitIntent from "../components/exitIntent"
import AgeVerification from "../components/ageVerification"
import Popup from "../components/popup"
import hrefLangTags from "./hrefLangTags"
import { Helmet } from "react-helmet"
//import BottomMenu from "./bottomMenu";
//import ScrollInNav from "../components/scrollInNav";

const Container = styled.div`
  background: #eef2f4;
  background: ${props => props.theme.background};
  overflow: hidden;
  filter: ${props => props.blur ? 'blur(4px)' : 'none'};
  .complianceModeHidden {
    display: none;
  }
`

function dynamicCount({ pageType, inputString = "", countParam }) {
  let count = countParam
  switch (pageType) {
    case "taxonomyParent":
    case "taxonomyChild":
    case "taxonomyFilter":
      inputString = inputString?.replace(/%COUNT/g, count ? count : "")
      inputString = inputString?.replace(/\[%s:count\]/g, count ? count : "")
      break
    case "page":
      count = "10"
      inputString = inputString?.replace(/\[%s:count\]/g, count ? count : "")

      break;
    default:
      break
  }
  return inputString
}

const LayoutComponent = props => {
  const {
    children,
    pageContext,
    path,
    htmlTitle: _htmlTitle,
    metaDescription: _metaDescription,
    image,
    ogImage,
    twitterImage,
    countParam = 0
  } = props
  const {
    site,
    modules,
    pageType,
    providersNetworkId,
    getFallbackProviders = false,
    themeSettings = {},
    taxonomy = {},
    filterTaxonomy = {},
    provider = {},
    product = {},
    page = {},
    post = {},
  } = pageContext
  const { complianceMode, showAgeVerification = {enabled:false} } = themeSettings
  const { showPopup = {enabled:false} } = themeSettings
  const [exitIntentOpen, setExitIntentOpen] = useState(false)
  const [ageVerificationOpen, setAgeVerificationOpen] = useState(showAgeVerification.enabled)
  const [showPopupOpen, setShowPopupOpen] = useState(showPopup.enabled)
  const { amp = {} } = modules
  const { enabled: ampEnabled, pathSuffix: ampPathSuffix } = amp
  const favIcon = site?.properties?.favicon?.resolved?.[0]?.url
  const logotype = themeSettings["logotype"] ? themeSettings["logotype"] : site?.properties?.logotype?.resolved?.[0]?.url
  //const theme = pageContext?.network?.global?.name === "Slots" ? slotsrankTheme : rankTheme
  const theme = themeSettings.theme

  let item
  switch (pageType) {
    case "taxonomyChild":
    case "taxonomyParent":
    case "taxonomyNews":
    case "taxonomyGuides":
      item = taxonomy
      break
    case "taxonomyFilter":
      item = filterTaxonomy
      break
    case "provider":
      item = provider
      break
    case "product":
      item = product
      break
    case "page":
      item = page
      break
    case "post":
      item = post
      break
    default:
      break
  }

  const ageVerificationEnabled = () => {
    if (typeof window !== 'undefined' && window.localStorage.getItem("didVerifyAge") !== "true" && showAgeVerification?.enabled) {
      return true
    } else {
      return false
    }
  }

  let removeExitIntent
  if (!exitIntentOpen && typeof window !== 'undefined') {
    removeExitIntent = exitIntent({
      threshold: 10,
      maxDisplays: 1,
      eventThrottle: 100,
      onExitIntent: () => {
        try {
          if (!exitIntentOpen && !window.localStorage.getItem("didShowExitIntent")) {
            window.localStorage.setItem("didShowExitIntent", true)
            setExitIntentOpen(true)
          }
        } catch {}
      }
    })
  }

  useEffect(() => () => {
    removeExitIntent()
  }, []);

  const htmlTitle = dynamicCount({
    pageType: pageType,
    inputString: _htmlTitle,
    countParam
  })

  const metaDescription = dynamicCount({
    pageType: pageType,
    inputString: _metaDescription,
    countParam
  })
  return (
    <GeoProvider complianceMode={complianceMode} site={site} siteLang={site?.language?.languageCode} providersNetworkId={providersNetworkId} getFallbackProviders={getFallbackProviders}>
      <GeoContext.Consumer>
        {geoContext => (
          <ThemeProvider theme={theme}>
          <Global
            styles={css`
              * {
                font-family: ${theme.fontFamily};
              }
            `}
          />
            <Container blur={ageVerificationEnabled() && ageVerificationOpen ? 1:undefined}>
              <Helmet>
                <html lang={site?.language?.languageCode} dir={site?.properties?.rtl?.data?.value ? "rtl" : "ltr"} />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                {theme?.fontLink ? (
                  <link href={theme?.fontLink} rel="preload" as="style" onload="this.onload=null;this.rel='stylesheet'" />
                ):null}

                {ampEnabled ? (
                  <link rel="amphtml" href={site.url + path + ampPathSuffix} />
                ) : null}
                <title>{htmlTitle}</title>
                {metaDescription ? (
                  <meta name="description" content={metaDescription} />
                ) : null}

                <link
                  href={favIcon ? cloudinary.url(favIcon, { width: 180, height: 180, crop: "scale" }) : "/apple-icon-180x180.png"}
                  rel="apple-touch-icon"
                  sizes="180x180"
                />
                <link href={favIcon ? cloudinary.url(favIcon, { width: 32, height: 32, crop: "scale" }) : "/favicon-32x32.png"} rel="icon" type="image/x-icon" />

                {/* Open Graph Data */}
                <meta property="og:title" content={item?.["og-title"] ? dynamicCount({ pageType, inputString: item?.["og-title"] }) : htmlTitle} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={site?.url?.indexOf("https://") === 0 ? site?.url + path : "https://" + site?.url + path} />
                <meta property="og:image" content={cloudinary.url(ogImage || image || logotype)} />
                <meta property="og:description"
                  content={item?.["og-description"] ? dynamicCount({ pageType, inputString: item?.["og-description"] }) : metaDescription} />

                {/* Twitter Card data */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@casinorank" />
                <meta name="twitter:creator" content="@casinorank" />
                <meta name="twitter:title" content={item?.["twitter-title"] ? dynamicCount({ pageType, inputString: item?.["twitter-title"] }) : htmlTitle} />
                <meta name="twitter:description" content={item?.["twitter-description"] ? dynamicCount({ pageType, inputString: item?.["twitter-description"] }) : htmlTitle} />
                <meta name="twitter:image" content={cloudinary.url(twitterImage || image || logotype)} />

                {hrefLangTags(props)}
              </Helmet>
              <Header {...props} {...geoContext} />
              {children}
              <Footer {...props} />
            </Container>

            {exitIntentOpen ? <ExitIntent {...props} onClose={() => setExitIntentOpen(false)} geoContext={geoContext} /> : null}
            {ageVerificationEnabled() && ageVerificationOpen ? <AgeVerification {...props} onClose={() => setAgeVerificationOpen(false)} geoContext={geoContext} /> : null}
            {showPopupOpen ? <Popup {...props} geoContext={geoContext} content={showPopup?.content} buttons={showPopup?.buttons} /> : null}


            {/* typeof window !== "undefined" && pageType !== "provider" && pageType !== "page" ? (
              <ScrollInNav scrollInHeight={200} top={false}>
                <BottomMenu geoContext={geoContext} {...props} />
              </ScrollInNav>
            ) : null */}

          </ThemeProvider>
        )}
      </GeoContext.Consumer>
    </GeoProvider>
  )
}
export default LayoutComponent
