import React, { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Row, Col } from "react-flexbox-grid"

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: ${props =>
    props.highlight ? props.theme.primaryColor : "transparent"};
`

const ToggleButton = styled.div`
  font-size: ${props =>
    props.large ? "14px" : "12px"};
  color: ${props =>
    props.large ? "#555" : (props.buttonTextColor ? props.buttonTextColor:"#fff")};
  padding: ${props =>
    props.large ? "8px 12px" : "0 5px 0 5px"};
  font-weight: ${props =>
    props.large ? "normal" : "300"};
  border: ${props =>
    props.large ? "1px solid #dfdfdf" : "none"};
  border-radius: ${props =>
    props.large ? props.theme.borderRadius : "0"};
  display:inline-block;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  background: ${props =>
    props.large ? "#fff" : "transparent"};

  ${props =>
      props.large &&
      css`
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

`

const DropDownContainer = styled(Row)`
  display: ${props => (props.open ? "flex" : "none")};
  position: absolute;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  color: #333;
  z-index: 9999999;
  left: ${props => (props.alignright ? "auto" : "0px")};
  right: ${props => (props.alignright ? "0px" : "auto")};
  top: calc(100% + 2px);
  min-width: 100%;
  padding: 5px 2px;
  margin-left: 0;
  max-height: 350px;
  overflow: scroll;
  box-shadow: rgb(72 72 72 / 10%) 0px 2px 5px 3px;
`

const DropdownItem = styled(Col)`
  font-size: 12px;
  display: block;
  user-select: none;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 10px !important;
    padding: 2px 5px !important;
  }

  a {
    display: block;
    color: #000;
    white-space: nowrap;
    padding: 8px 5px;
  }

  &:hover {
    background: ${props => props.theme.tertiaryColor};
  }
`

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
      //alert("You clicked outside of me!")
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
}

export default props => {
  const { menuItems = [], alignright, large, className, title, columns, buttonTextColor } = props
  const [open, setOpen] = useState(false)

  function closeMenu() {
    setOpen(false)
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, closeMenu)

  const activeItem = title ? { title: title, url: "#" } : menuItems.find(menuItem => {
    return menuItem.active
  })

  return (
    <Container ref={wrapperRef} className={className}>
      <ToggleButton onClick={() => setOpen(!open)} large={large} buttonTextColor={buttonTextColor}>
        {activeItem?.title}

      <svg width="12px" height="7px" viewBox="0 0 12 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="ml-2">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-6.000000, -9.000000)" fill="#81858B">
                  <path d="M15.88,9.29 L12,13.17 L8.12,9.29 C7.73,8.9 7.1,8.9 6.71,9.29 C6.32,9.68 6.32,10.31 6.71,10.7 L11.3,15.29 C11.69,15.68 12.32,15.68 12.71,15.29 L17.3,10.7 C17.69,10.31 17.69,9.68 17.3,9.29 C16.91,8.91 16.27,8.9 15.88,9.29 L15.88,9.29 Z" id="chevron-down"></path>
              </g>
          </g>
      </svg>
      </ToggleButton>
      <DropDownContainer
        open={open ? 1 : undefined}
        alignright={alignright ? 1 : undefined}
        columns={columns ? columns : undefined}
      >
        {menuItems.map((menuItem, i) => (
          <DropdownItem xs={columns ? 12 / columns : 12} key={i}>
            <a href={menuItem.url}>{menuItem.title}</a>
          </DropdownItem>
        ))}
      </DropDownContainer>
    </Container>
  )
}
