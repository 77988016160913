import React from "react"
import styled from "@emotion/styled";

const FlagContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;

  .checkmark {
    position: relative;
    left: -15px;
    width: 22px;
    height: 22px;

    @media only screen and (min-width: 768px) {
      width: 25px;
      height: 25px;
    }
  }
`

const Flag = styled.img`
  width: 20px;
  height: 20px;
  object-fit: ${props => props.fit ? props.fit : "cover"};
  margin: 0;
  padding: 0;
  margin-bottom: 0px;
  display: inline-block;
  border-radius: 50px;

  @media only screen and (min-width: 768px) {
    width: 25px;
    height: 25px;
  }
`

export default props => {
  const {
    countryCode,
    className
  } = props

  return (
    <FlagContainer className={className}>
      <Flag src={`/flags/${countryCode}.svg`} alt={countryCode + " Country Flag"} fit="cover" className="mr-2" />
      <img src="/img/checkmark.svg" className="checkmark" alt={"Checkmark"}/>
    </FlagContainer>
  )
}
