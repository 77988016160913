const { resolver, buildParameters } = require("cloudinary-url-resolver");

resolver.settings.cloud_name =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || "wdnetwork";
resolver.settings.secure = true;
/**
 * The complete Triforce, or one or more components of the Triforce.
 * @typedef {Object} CloudinaryUrlConfig
 * @property {number} width
 * @property {number} height
 * @property {string} scale
 * @property {string} format
 */

/**
 *
 * @param {String} filename
 * @param {CloudinaryUrlConfig} config
 * @returns {String}
 */

const url = function (url, config = {}, disableRewrites=false) {
  if (!url) return undefined;
  const options = {
    fetch_format: "auto",
    crop: "fill",
    quality: "auto",
    flags: ["progressive"],
    ...config,
  };
  const parameters = buildParameters(options);
  return `${url}?transformations=${parameters.join(",")}`
};

module.exports = {
  url,
};
