import React, {useState} from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Grid, Row, Col } from "react-flexbox-grid"
import { Section, TintedSection } from "../components/styledComponents"
import styled from "@emotion/styled"

const Footer = styled(Section)`
  background: ${props => props.theme.primaryColor};
  color: #fff;
`

const MenuColumn = styled.div`
  width: 100%;
  border-bottom: ${props => props.last ? "none" : "1px solid rgba(255,255,255,0.2)"};

  @media only screen and (min-width: 768px) {
    width: 20%;
    border-bottom: none;
  }
`

const CopyrightNotice = styled.span`
  margin: 0 auto;
  color: #333;
  display: block;
  text-align: center;
  font-size: 12px;
`

const GamblingLogo = styled(LazyLoadImage)`
  margin: 0.75rem 30px;
  max-height: 35px;
  max-width: 100%;
  opacity: 55%;
`

const SocialIcon = styled.a`
  cursor: pointer;
  opacity: 0.5;
  margin-right: 10px;

  & > img {
    width: 50px;
  }
`

const LinksHeader = styled.div`
  font-weight: 400;
  font-size: 22px;
  color: #fff;
  display: flex;
  justify-content: space-between;
`

const StyledLink = styled.a`
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  padding: 0;
  display: block;
  opacity: 70%;
`

const ExpandButton = styled(LazyLoadImage)`
  filter: ${props => props.filterFooterMenuToggles ? "brightness(15)":"none"}
`

const MenuSection = props => {
  const {m, menuItems, last = false, scrollPosition, filterFooterMenuToggles = false, themeSettings = {}} = props
  const [expanded, setExpanded] = useState(false)

  return (
    <MenuColumn className="py-3 py-md-0" last={last}>
      <Row className="flex-column mx-0" style={{flex:"1 1 auto"}}>
        {menuItems?.length ? (
          <>
            <LinksHeader className="mb-0 mb-md-3" onClick={() => setExpanded(!expanded)}>
              <span>{m.title}</span>

              {expanded ? (
                <ExpandButton
                  src="/img/collapse.svg"
                  alt="Collapse"
                  scrollPosition={scrollPosition}
                  className="d-block d-md-none"
                />
              ):(
                <ExpandButton
                  src="/img/expand.svg"
                  alt="Expand"
                  scrollPosition={scrollPosition}
                  className="d-block d-md-none"
                  filterFooterMenuToggles={filterFooterMenuToggles}
                />
              )}
            </LinksHeader>
              <div className={"mt-2 mt-md-0" + (expanded ? " d-block":" d-none d-md-block")}>
                {menuItems?.map((menuItem, i) => (
                  <StyledLink href={menuItem.href} key={i}>
                    {menuItem.title}
                  </StyledLink>
                ))}
              </div>
          </>
        ) : null}
      </Row>
      {m.social ? (
        <Row className={"my-3 mx-0" + (expanded ? " d-block":" d-none d-md-block")}>
          <SocialIcon
            href="https://www.facebook.com/CasinoRankCOM"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyLoadImage
              src="/img/fb.svg"
              alt="Follow CasinoRank® on Facebook"
              scrollPosition={scrollPosition}
            />
          </SocialIcon>
          <SocialIcon
            href="https://twitter.com/CasinoRank_com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyLoadImage
              src="/img/twitter.svg"
              alt="Follow CasinoRank® on Twitter"
              scrollPosition={scrollPosition}
            />
          </SocialIcon>
        </Row>
      ):null}

      {m.icon ? (
        <Row className={"my-3 mx-0" + (expanded ? " d-block":" d-none d-md-block")}>
          <SocialIcon
            href="#"
          >
            <LazyLoadImage
              src={themeSettings?.ageLimitIconWhite ? themeSettings.ageLimitIconWhite : "/img/18plus.svg"}
              alt="Responsible Gambling"
              scrollPosition={scrollPosition}
            />
          </SocialIcon>
        </Row>
      ):null}
    </MenuColumn>
  )
}

export default props => {
  const { pageContext, footerLight, scrollPosition } = props
  const { site, footerMenu, themeSettings = {}, modules = {} } = pageContext
  const copyrightNotice =
    "© " + site?.properties?.name?.data?.value + " " + new Date().getFullYear()
  const menuColumns = [
    {title: site?.["footer-menu-about-title"] || "About", social: true, menuItems: modules?.["footer-menu-about"]},
    {title: site?.["footer-menu-reviews-title"] || "Reviews", menuItems: modules?.["footer-menu-reviews"]},
    {title: site?.["footer-menu-guides-title"] || "Game guides", menuItems: modules?.["footer-menu-guides"]},
    {title: site?.["footer-menu-news-title"] || "News", menuItems: modules?.["footer-menu-news"]},
    {title: site?.["footer-menu-for-players-title"] || "For players", icon: true, menuItems: modules?.["footer-menu-players"]},
  ]

  return (
    <>
      {site?.["compliance-notice"] ? (
        <TintedSection className="py-4">
          <Grid style={{border:"1px solid #dfdfdf", borderRadius: "5px"}}>
            <div
              className="text-center"
              dangerouslySetInnerHTML={{ __html: site?.["compliance-notice"] }} />
          </Grid>
        </TintedSection>
      ):null}
      <Footer className="pt-1 pt-md-5 pb-0" light={footerLight ? 1 : undefined}>
        <Grid>
          <Row className="my-2 my-md-4 mb-0 mb-md-3 px-4 px-md-2">
            {menuColumns.map((m,i) => {
              return (
                <MenuSection m={m} i={i} menuItems={m.menuItems} scrollPosition={scrollPosition} last={i === menuColumns.length - 1} filterFooterMenuToggles={themeSettings?.theme?.filterFooterMenuToggles} themeSettings={themeSettings}/>
              )
            })}
          </Row>


          <Row className="mt-0 mt-md-3 py-3 align-items-center justify-content-center" style={{borderTop: "1px solid rgba(255,255,255,0.15)"}}>
              <a
                href="https://www.ncpgambling.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GamblingLogo
                  src="/img/ncpg.svg"
                  alt="NCPG"
                  scrollPosition={scrollPosition}
                />
              </a>

              <a
                href="https://www.gamcare.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GamblingLogo
                  src="/img/gamcare.svg"
                  alt="GamCare Responsible Gaming"
                  scrollPosition={scrollPosition}
                />
              </a>

              <a
                href="https://www.begambleaware.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GamblingLogo
                  src="/img/begambleaware.svg"
                  alt="BeGambleAware - Gamble responsibly"
                  scrollPosition={scrollPosition}
                />
              </a>

              <a
                href="https://www.gamblinghelponline.org.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GamblingLogo
                  src="/img/gamblinghelp.svg"
                  alt="Gambling Help Online"
                  scrollPosition={scrollPosition}
                />
              </a>
              {/*
              <a
                href="https://www.stodlinjen.se/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GamblingLogo
                  src="/img/stodlinjen_white.svg"
                  alt="Stödlinjen"
                  scrollPosition={scrollPosition}
                />
              </a>
              */}

              <a
                href="https://www.gamblersanonymous.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GamblingLogo
                  src="/img/ga.svg"
                  alt="Gamblers Anonymous"
                  scrollPosition={scrollPosition}
                />
              </a>

              <a
                href="https://www.camh.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GamblingLogo
                  src="/img/camh.svg"
                  alt="CAMH"
                  scrollPosition={scrollPosition}
                />
              </a>


          </Row>

          <Row className="flex-column flex-md-row justify-content-center align-items-center pt-2 pb-3 py-md-3">
            {footerMenu?.length ? (
              <>
                {footerMenu?.map((menuItem, i) => (
                  <StyledLink href={menuItem.href} key={i} className="px-3">
                    {menuItem.title}
                  </StyledLink>
                ))}
                <StyledLink href={"/sitemap/"}>
                  Sitemap
                </StyledLink>
              </>
            ) :
              null}
          </Row>

        </Grid>

        <Section background="#fff" className="py-3">
          <Grid>
            <Row className="d-flex flex-row align-items-center justify-content-between">
              <Col xs={1}><LazyLoadImage scrollPosition={scrollPosition} src="/img/dmca.svg" alt="DMCA" /></Col>
                <CopyrightNotice className="mt-0">{copyrightNotice}</CopyrightNotice>
              <Col xs={1} />
            </Row>
          </Grid>
        </Section>
      </Footer>
    </>
  )
}
