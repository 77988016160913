import React from "react"

export default function hrefLangTags(props) {
  const { pageContext } = props
  let { deepLinks = true } = props
  const {
    pageType,
    site = {},
    vertical = {},
    taxonomy = {},
    filterTaxonomy = {},
    provider = {},
    product = {},
    page = {},
    post = {},
    isExtendedReviewSubPage = false,
    extendedReviewSection = {},
    pagination = {}
  } = pageContext
  let output = []

  let item = {}

  switch (pageType) {
    case "taxonomyChild":
    case "taxonomyParent":
    case "taxonomyNews":
    case "taxonomyGuides":
      item = taxonomy
      break
    case "taxonomyFilter":
      item = filterTaxonomy
      break
    case "provider":
      item = provider
      break
    case "product":
      item = product
      break
    case "page":
      item = page
      break
    case "post":
      item = post
      break
    case "htmlSitemap":
      item = {
        path:"/sitemap/"
      }
      break
    default:
      break
  }

  let { path = "/" } = item

  if (isExtendedReviewSubPage) {
    path = extendedReviewSection.path
  }

  if (pagination && pagination?.currentPage > 1) {
    deepLinks = false
  }

  const siteUrl = site?.url?.indexOf("https://") === 0 ? site?.url : "https://" + site?.url

  output.push(
    <link
      key={"canonical"}
      rel="canonical"
      href={path === "/" ? siteUrl : siteUrl + path}
    />
  )

  if (deepLinks && vertical?.sites) {
    vertical.sites.forEach((s, i) => {

      let url = s?.url?.indexOf("https://") === 0 ? s?.url : "https://" + s?.url
      let localisedPath = item?.["translatedPaths"]?.[s?.language?.languageTag]

      if (s.url === site.url || pageType === "htmlSitemap") {
        localisedPath = path
      }

      if (isExtendedReviewSubPage && localisedPath) {
        localisedPath = extendedReviewSection.path
      }

      if (localisedPath || path === "/" || s.url === site.url) {
        url = path === "/" ? url : url + localisedPath
        output.push(
          <link
            key={i}
            rel="alternate"
            href={url}
            hreflang={s?.language?.languageTag}
          />
        )

        if (s?.language?.languageTag === "en") {
          output.push(
            <link key={i} rel="alternate" href={url} hreflang={"x-default"} />
          )
        }
      }
    })
  }

  return output
}
