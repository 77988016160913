import React, {useState} from "react";
import styled from "@emotion/styled";
import { Button } from "../components/styledComponents"

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
`;

const Container = styled.div`
  background: ${props => props.theme.tertiaryColor};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 600px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 5px;
  text-align: center;
  z-index: 999999999;

  h3 {
    color: #000;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }

  .close {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .warning {
    color: red;
  }
`;

const ExitIntent = (props) => {
  const { pageContext } = props;
  const { themeSettings = {} } = pageContext;
  const { showAgeVerification = {}  } = themeSettings;
  const [ showVerificationFailed, setShowVerificationFailed ] = useState(false)

  const didVerifyAge = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("didVerifyAge", "true");
      props.onClose()
    }
  }

  return (
    <Backdrop>
      <Container onClick={(e) => e.stopPropagation()} className="px-5 py-3">
        <div
          dangerouslySetInnerHTML={{ __html: showAgeVerification.content }} />


        <Button
          className="mr-2"
          onClick={() => didVerifyAge()}
          onKeyDown={() => didVerifyAge()}
          role="button"
          tabIndex={0}
          aria-label="Confirm"
          >
            {showAgeVerification.confirmButtonTitle}
        </Button>

        <Button
          className="ml-2"
          onClick={() => setShowVerificationFailed(true)}
          onKeyDown={() => setShowVerificationFailed(true)}
          role="button"
          tabIndex={0}
          aria-label="Decline"
          >
            {showAgeVerification.declineButtonTitle}
        </Button>

        {showVerificationFailed ? (
          <p class="warning">{showAgeVerification.verificationFailedContent}</p>
        ):null}
      </Container>
    </Backdrop>
  );
};

export default ExitIntent;
