import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Row } from "react-flexbox-grid";

export const Card = styled.div`
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-color: #f2105a;
  border-radius: 5px;
  flex-direction: column;
  border: 0 !important;

  & > h2 {
    margin-top: 0;
  }
`;

export const Section = styled.div`
  padding: 20px 0;
  background: ${(props) =>
    props.background ? props.background : "transparent"};
  position: relative;
  overflow: visible;

  .intro {
    color: #333;
    text-align: center;
    font-size: 18px;
  }

  ${(props) =>
    props.hero &&
    css`
      padding-top: 78px;
      padding-bottom: 0;

      @media only screen and (min-width: 992px) {
        padding-top: 112px;
      }
    `}
`;

export const TintedSection = styled(Section)`
  background: ${props => props.theme.tertiaryColor};
`

export const HeroSection = styled(Section)`
  position: relative;
  background-image: ${props => props.theme.heroBackground};
  color: #fff;
  padding-top: 78px;
  padding-bottom: 0;

  @media only screen and (min-width: 992px) {
    padding-top: 112px;
  }

  & .container {
    position: relative;
  }

  .mainContent {
    color: #fff;
  }
  .mainContent a {
    color: #fff;
    text-decoration: underline;
  }

  .viewMore {
    font-size: 20px;
    font-weight: 600;
    margin: 10px auto;
    display: block;
  }
`

export const StyledSection = styled(Section)`
  background: ${props => props.theme.tertiaryColor};
`;

export const MarkdownContent = styled.div`
  a {
    text-decoration: underline;
    color: ${props => props.theme.secondaryColor}
  }

  img {
    width: 100%;
    margin-bottom: 40px;
    margin: 2rem 0;
    border-radius: 6px;
  }

  p {
    margin-bottom: 20px;
  }

  & > h2 {
    margin-top: 0;
  }

  h2, h3, h4 {
    margin-top: 40px;
  }
  /*
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }
  */
`;

export const Chip = styled.a`
  cursor: pointer;
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  background: ${props => props.theme.secondaryColor};
  border-radius: ${props => props.theme.borderRadius};
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
`;

export const StaticChip = styled.div`
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  color: rgb(85, 85, 85);
  text-transform: capitalize;
  font-size: 12px !important;
  background-color: rgb(255, 255, 255) !important;
  margin: 2px;
  padding: 5px 8px;
  border-radius: 50px !important;
  border: 1px solid #dfdfdf;
`;


export const PropertyChip = styled.a`
  cursor: pointer;
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  background: ${props => props.theme.chipBackground ? props.theme.chipBackground : props.theme.secondaryColor};
  border-radius: ${props => props.theme.borderRadius};
  padding: 5px 10px;
  color: ${props => props.theme.chipTextColor ? props.theme.chipTextColor : "#fff"};
  font-size: 14px;
  text-transform: capitalize;
`;

export const StaticPropertyChip = styled.div`
  cursor: pointer;
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  background: ${props => props.theme.chipBackground ? props.theme.chipBackground : props.theme.secondaryColor};
  border-radius: ${props => props.theme.borderRadius};
  padding: 5px 10px;
  color: ${props => props.theme.chipTextColor ? props.theme.chipTextColor : "#fff"};
  font-size: 14px;
  text-transform: capitalize;
`;

export const CtaButton = styled.a`
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 15px 5px;
  min-width: 112px;
  font-size: 16px;
  min-height: 40px;
  color: #fff !important;
  background: ${(props) => props.theme.ctaColor};
  transition: all 0.1s;
  min-height: 36px;
  box-sizing: border-box;
  line-height: 1.4em;
  font-weight: 600 !important;
  border-radius: 50px;
  border-radius: 5px;
  text-transform: none;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;

  @media only screen and (min-width: 992px) {
    padding: 15px 5px;
    font-size: 16px;
  }
`;


export const Button = styled.a`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  background: #fff;
  margin: 0 auto;
  color: ${(props) => props.theme.secondaryColor} !important;
  padding: 20px 30px;
  border-radius: 50px;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  text-transform: uppercase;
`;


export const ButtonBasic = styled.a`
  text-align: center;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
  margin: 0 auto;
  color: #fff;
  background: #000;
  padding: 10px 20px;
  border-radius: 7px;
  text-transform: uppercase;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const Arrow = styled.svg`
  margin-left: 3px;
  display: inline-block;
  div,
  svg {
    position: relative;
    width: 10px;
    top: -1px;
  }

  polygon {
    stroke-width: 0;
    stroke: #000;
    fill: #000;
  }
`;

export const ArrowRight = (props) => {
  const { className } = props;
  return (
    <Arrow width="10px" height="9px" viewBox="0 0 10 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={className}>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill="#7857ff">
            <path d="M1.45971407,4.89931456 L7.13369699,4.89931456 L4.9414942,7.14890297 C4.76629766,7.32868565 4.76629766,7.62371364 4.9414942,7.80349632 C5.11669073,7.983279 5.39970052,7.983279 5.57489705,7.80349632 L8.53526927,4.76563001 C8.7104658,4.58584733 8.7104658,4.29542915 8.53526927,4.11564647 L5.57938927,1.07317034 C5.40419274,0.893387663 5.12118295,0.893387663 4.94598642,1.07317034 C4.77078988,1.25295302 4.77078988,1.5433712 4.94598642,1.72315388 L7.13369699,3.9773521 L1.45971407,3.9773521 C1.21264203,3.9773521 1.01049218,4.18479366 1.01049218,4.43833333 C1.01049218,4.69187301 1.21264203,4.89931456 1.45971407,4.89931456 Z"></path>
        </g>
      </g>
    </Arrow>
  );
};

export const ExpandableP = styled.div`
  max-height: ${(props) => (props.expanded ? "auto" : "120px")};
  overflow: hidden;
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 25px;

  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  line-height: 1.3em;

  color: ${(props) => (props.color ? props.color + " !important" : "#333")};

  & p {
    max-height: ${(props) => (props.expanded ? "auto" : "120px")};
  }

  & > h2,
  h3,
  h4 {
    color: ${(props) => props.color + " !important" || "#333"};
  }

  & > h1 {
    font-size: 2em;
  }

  & > h2 {
    font-size: 20px;
    line-height: normal;
  }

  & > h3 {
    font-size: 20px;
    line-height: normal;
  }

  & > h4 {
    font-size: 20px;
    line-height: normal;
  }

  &:after {
    ${(props) =>
    !props.expanded &&
    css`
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;

        width: 100%;
        height: 4em;
      `}
  }
`;

export const ReadMore = styled.span`
  color: ${(props) => props.color ? props.color : "#777"};
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  padding: 0 15px;
  padding-left: 0;
  background: linear-gradient(to left, white 90%, rgba(255, 255, 255, 0));
  background: transparent;
  cursor: pointer;
`;

export const ContentSectionsMenu = styled(Row)`
  border: 1px solid ${props => props.theme.secondaryColor};
  border-radius: ${props => props.theme.borderRadius};

  a {
    color: ${props => props.theme.secondaryColor};
  }
`

export const SmallLabel = styled.span`
  font-size: 11px;
  color: ${props => props.theme.textFaded};
  text-transform: uppercase;
`
