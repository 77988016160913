import React from "react"
const GeoContext = React.createContext()

class GeoProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countryCode: null,
      countryName: null,
      localizedCountryName: null,
      rankingData: null,
      providers: [],
      fieldNames: {},
      acceptedPlayers: null,
    }
  }

  getGeoData = ({ siteLang = "en", providersNetworkId }) => {
    const { site, getFallbackProviders = false } = this.props
    const _this = this

    if (typeof window === "undefined") {
      return
    }

    if (!this.state.countryCode) {
      try {
        const _storedGeoData = window.localStorage.getItem("geoData")
        if (_storedGeoData) {
          const storedGeoData = JSON.parse(_storedGeoData)
          this.setState({
            ...storedGeoData,
          })
        }
      } catch {}

      const _window = window
      let rankingEndpoint = `https://geo.xstat.org/getProviders?networkId=${providersNetworkId}&siteId=${site?.allanId}&lang=${siteLang}&getFallbackProviders=${getFallbackProviders}`

      if (getFallbackProviders) {
        rankingEndpoint = `https://geo.xstat.org/getRankings?networkId=${providersNetworkId}&siteId=${site?.allanId}&lang=${siteLang}&getFallbackProviders=${getFallbackProviders}`
        let siteUrl = site?.url?.replace("https://","")
        rankingEndpoint = rankingEndpoint += `&host=${siteUrl}`
      }

      fetch(rankingEndpoint)
        .then(res => res.json())
        .then(
          result => {
            let fieldNames = {}
            result.providers && result.providers[0] && Object.keys(result.providers[0].properties).forEach(key => {
              const property = result.providers[0].properties[key]
              if (property.templateField) {
                fieldNames[key] = property.templateField && property.templateField.content && property.templateField.content && property.templateField.content[0] && property.templateField.content[0].strings && property.templateField.content[0].strings[0] && property.templateField.content[0].strings[0].data
              }
            })

            const geoData = {
              countryCode: result.countryCode,
              countryName: result.countryName,
              localizedCountryName: result.localizedCountryName,
              rankingData: result.rankingData,
              providers: result.providers,
              fieldNames: fieldNames,
              acceptedPlayers: result.acceptedPlayers
            }
            _this.setState({
              ...geoData,
            })
            try {
              _window.localStorage.setItem("geoData", JSON.stringify(geoData))
            } catch {}
          },
          error => {
            console.log("Err: ", error)
          }
        )
    }
  }
  componentDidMount() {
    this.getGeoData({ siteLang: this.props.siteLang, providersNetworkId: this.props.providersNetworkId })

    // Set pageview count to rotate providers in featured lists
    const maxRotation = 12
    let pageViewCount = parseInt(window.localStorage.getItem("pageviewCount"))
    pageViewCount = pageViewCount < maxRotation ? pageViewCount + 1 : 0
    window.localStorage.setItem("pageviewCount", pageViewCount)
  }
  render() {
    const { children, complianceMode } = this.props
    const { countryCode, countryName, localizedCountryName, rankingData, providers, fieldNames, acceptedPlayers } = this.state

    return (
      <GeoContext.Provider
        value={{
          countryCode,
          countryName,
          localizedCountryName,
          rankingData,
          providers: complianceMode ? [] : providers,
          fieldNames,
          acceptedPlayers
        }}
      >
        {children}
      </GeoContext.Provider>
    )
  }
}
export default GeoContext
export { GeoProvider }
