import React from "react";
import styled from "@emotion/styled";
import FeaturedProviders from "./featuredProviders";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
`;

const Container = styled.div`
  background: ${props => props.theme.tertiaryColor};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 1000px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 5px;
  text-align: left;
  z-index: 999999999;

  h3 {
    color: #000;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }

  .close {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
  }
`;

const ExitIntent = (props) => {
  const { geoContext, pageContext } = props;
  const { site, themeSettings = {} } = pageContext;
  const { complianceMode, disableListings = false  } = themeSettings;

  if (complianceMode) return null
  if (disableListings) return null
  if (themeSettings?.disableExitIntent) return null

  return (
    <Backdrop onClick={() => props.onClose()}>
      <Container onClick={(e) => e.stopPropagation()} className="px-5 py-3">
        <h3>{site?.["exit-intent-title"]}</h3>
        <FeaturedProviders
          {...props}
          providers={geoContext?.providers}
          count={3}
          filterRestricted={site?.properties?.country?.data ? true : false}
          rating={true}
          flag={true}
          reviewLinks={themeSettings?.reviewLinks}
          depositMethods={true}
          outboundClickPrefix="exit_"
        />
        <div
          className="close"
          onClick={() => props.onClose()}
          onKeyDown={() => props.onClose()}
          role="button"
          tabIndex={0}
          aria-label="Close"
          >
            <img src="/img/close.svg" alt="Close" />
        </div>
      </Container>
    </Backdrop>
  );
};

export default ExitIntent;
